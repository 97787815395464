<template lang="pug">
  base-input-file(
    ref="baseInput"
    :loading="isDisabled"
    @input="inputFile"
  )
    template(#activator)
      slot
        v-btn(color="primary" :disabled="disabled" :loading="loading" outlined small) Bulk photo upload
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin';

export default {
  inject: ['candidatesActionsSvc'],

  mixins: [errorsMixin],

  props: {
    course: Object,
    activeItems: Array,
    courseID: Number,
    disabled: Boolean
  },

  data: () => ({
    loading: false
  }),

  computed: {
    hasCoursePhoto() {
      return !!(this.course && this.course.course_photo);
    },

    isDisabled() {
      return this.loading || this.disabled;
    }
  },

  methods: {
    async inputFile(value) {
      try {
        this.loading = true;
        let candidatesIDList = this.activeItems.map(item => item.ID);
        await this.candidatesActionsSvc().uploadCoursePhoto(candidatesIDList, value);
        this.clear()
        this.$notify({text: 'Successfully uploaded', type: 'success'})
      } catch (error) {
        console.log(error);
        this.processError(error);
      }

      setTimeout(() => { this.loading = false }, 500)
    },

    clear() {
      this.$refs.baseInput.clear()
    }
  },

  components: {
    baseInputFile: () => import('@/components/global/actions/BaseInputFile.vue')
  }
}
</script>
